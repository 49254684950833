.app[data-v-d3e0cf47] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
canvas[data-v-d3e0cf47] {
  background: #fff;
  position: fixed;
  z-index: 100;
}
.right-panels[data-v-d3e0cf47] {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 150;
}
.info-panel[data-v-d3e0cf47] {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
}
