.button[data-v-17df6e09] {
  display: inline-block;
  padding: 5px;
  margin: 3px;
  background: #ddd;
  color: #fff;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button--active[data-v-17df6e09] {
    background: #666;
}
.button[data-v-17df6e09]:active {
    background: #333;
}
