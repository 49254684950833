.panel[data-v-f586f9a3] {
  position: relative;
  background: #999;
  width: 300px;
  height: 500px;
  z-index: 150;
}
.panel-title[data-v-f586f9a3] {
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
}
.panel-content[data-v-f586f9a3] {
    overflow-y: auto;
}
.panel-footer[data-v-f586f9a3] {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
}
